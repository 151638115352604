
.comment-dialog-input-initial {
    color: gray;
    height: 4em;
    width: 100%;
}

.comment-dialog-input-user {
    color: black;
    height: 4em;
    width: 100%;
}


.comment-submit {
    background-color: cadetblue;
    color: whitesmoke;
    font-weight: bold;
    position: relative;
    vertical-align: baseline;
}


.reply {
    cursor: pointer;
    display: inline-block;
    font-family: 'Microsoft YaHei';
    padding-left: 15px;
    margin: 0 0 0 0.25em;
    color: gray;
    background-color: transparent;
    border: none;
}

.reply:hover {
    color: blue;
}

.comment-author {
    font-size: 15px;
    font-weight: bold;
}

.comment-timestamp {
    color: gray;
    font-size: 12px;
}

.comment-avatar {
    float: left;
    height: auto;
    width: 2.5em;
    display: block;
    margin: 0.2em 0 0;
}

.comment-body {
    display: block;
    margin-left: 3.5em;
}

.comment-board {
    display: inline-block;
    margin-top: 80px;
}