ul.no-bullets {
    list-style-type: none;
    /* Remove bullets */
}

p {
    font-family: "Microsoft YaHei", "WenQuanYi Micro Hei", Helvetica, Arial, Vernada, Tahoma, STXihei, "Microsoft YaHei", "Songti SC", SimSun, Heiti, sans-serif;
}

img {
    max-width: 100%;
    max-height: 100%;
}

a {
    text-decoration: none;
}

a:hover,
a:focus {
    color: #2a6496;
    text-decoration: underline;
}

p {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-family: "lucida grande", "lucida sans unicode", lucida, helvetica, "Hiragino Sans GB", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
    font-style: normal;
    font-size: 15px;
}

h3 {
    color: #c60e5b;
}

.article {
    margin: 0% 10%;
}

@media screen and (max-width: 600px) {
    p {
        font-size: 19px;
    }
}