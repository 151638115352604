.each-slide-effect > div {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 1024px;
}


hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 2px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

#storybook-root,
#sigma-container {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.login-card {
    margin: auto;
    width: 20%;
}

label {
    text-align: left;
}

.error {
    color: red;
    font-size: 20px;
    padding: 10px;
    margin: auto;
    width: 50%;
}