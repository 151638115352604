/* required to show leaflet map (the library has a corresponding class) */
.leaflet-container {
    margin: 30px auto;
    height: 500px;
    z-index: 0;
    border: 2px solid #333;
    overflow: hidden;
}

.leaflet-map-container {
    width: 100%;
    margin: auto;
    border-radius: 5px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
}

.population-county-graph-info-panel {
    max-height: 25%;
    max-width: 40%;
    width: 400px;
    padding: 6px 8px;
    font: 13px/1 sans-serif;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    background: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
}

.housing-city-map-info-panel {
    width: 140px;
    padding: 6px 8px;
    font: 14px/16px Arial, Helvetica, sans-serif;
    background: white;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    display: inline-block;
    margin: 10px 10px 0 0;
}

.houseing-city-map-legend {
    line-height: 18px;
    color: #555;
    padding: 6px 8px;
}

.houseing-city-map-legend i {
    width: 18px;
    height: 18px;
    float: left;
    margin-right: 8px;
    opacity: 0.7;
}

.leaflet-county-img {
    max-height: 25%;
    max-width: 60%;
}

.nivo-container {
    text-align: center;
}